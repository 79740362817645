<template>
  <v-app>
    <v-content>
        <div class="views">
          <router-view/>
        </div>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
$borderRadius: 10px;
$darkColor: #373542;

$break-small: 600px;

.panel {
  width: 150px;
  height: 100%;
  background-color: #2a2833;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__scroll {
    width: 100%;
    background-color: #383644;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .list {
    margin: 10px auto;
    width: 80%;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.views {
  // width: calc(100% - 255px);
  // left: 255px;
  // position: absolute;
  // overflow-y: scroll;
  // height: calc(100% - 75px);
  // padding-top: 2em;
  
  @media screen and (max-width: $break-small) {
    width: 100%;
    left: 0;
    position: relative;
    overflow-y: unset;
  }
}

#app {
  background-color: #fefefe;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @font-face {
	font-family: 'Avalon Bold';
	src: url('../static/fonts/Avalon-Bold.otf'); 
	src: 
		local('Avalon Bold'),
		local('Avalon'),
		url('../static/fonts/Avalon-Bold.otf') 
		format('opentype');
}


  @font-face {
	font-family: 'ProximaNova-Bold';
	src: url('/static/fonts/ProximaNova-Bold.otf'); 
	src: 
		local('ProximaNova-Bold'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Bold.otf') 
		format('opentype');
}

  @font-face {
	font-family: 'ProximaNova-Light';
	src: url('/static/fonts/ProximaNova-Light.otf'); 
	src: 
		local('ProximaNova-Light'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Light.otf') 
		format('opentype');
}

  @font-face {
	font-family: 'ProximaNova-Thin';
	src: url('/static/fonts/ProximaNova-Thin.otf'); 
	src: 
		local('ProximaNova-Thin'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Thin.otf') 
		format('opentype');
}

  .logo {
    display: none;
    transition: 3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    @media screen and (max-width: $break-small) {
      margin: 0 auto 40px auto;
      width: 80%;
      display: block;
    }
  }

  .logo-icon {
    width: 80%;
    margin: -40px 0 40px -140px;
    
  }


}

  .st0{fill:#3EC196;}
  .st1{fill:#373542; transition: 4s all cubic-bezier(0.075, 0.82, 0.165, 1);}
  .st1-hide {
    fill: none;
  }

.site-notification {
  background-color: #3EC196;
  width: 100%;
  position: fixed;
  top: 0;
  height: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  left: 0;
  font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;
  overflow: hidden;
  transition: all .5s cubic-bezier(0.86, 0, 0.07, 1);
  font-size: 14px;
  z-index: 999;
  @media screen and (max-width: $break-small) {
    font-size: 4vw;
  }
}
.site-notification-active {
  height: 3vw;
  @media screen and (max-width: $break-small) {
    height: 8vw;
  }
}

.trigger {
  cursor: pointer;
  background-color: #fae9e8;
  color: #e8908a;
  border-radius: 50px;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  font-size: 10px;
  padding: 1vw 35px 1vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7.5px;
  }
  @media screen and (max-width: $break-small) {
    padding: 1vw 35px 1vw 4vw;
  }
}
.trigger-color {
  cursor: pointer;
  background-color: #383644;
  color: #7b6996;
  border-radius: 50px;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  font-size: 8px;
  padding: 1vw 35px 1vw 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 70px;
  position: absolute;
  bottom: 50px; 
  left: 50%;
  margin-left: -60px;
  
  img {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7.5px;
  }
  @media screen and (max-width: $break-small) {
    padding: 1vw 35px 1vw 4vw;
  }
}
.tools-low {
  background-color: #e6ffee;
  color: #3ec196;
}
.tools-medium {
  background-color: #cac3d5;
  color: #7b6996;
}
.tools-high {
  background-color: #fae9e8;
  color: #e8908a;
}
.tools {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  padding: 5px 10px;
  background-color: #fff;
  left: -10px;
  top: 100%;
  z-index: 99;
  border-radius: $borderRadius;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 24px 0px;
  transform: scale(0.8);
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
    @media screen and (max-width: $break-small) {
      padding: 4vw 6vw;
    }

}

  .tool {
    text-transform: uppercase;
    font-size: 8px;
    margin: 10px;
    border-radius: 50px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: unset;
    max-width: 50px;

  @media screen and (max-width: $break-small) {
    font-size: 10px;
    margin: 4vw 0;
    border-radius: 50px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    padding: 2vw 4vw;
  }
  }

.tools-active {
  pointer-events: unset;
  opacity: 1;
  transform: scale(1);

}
.spacer {
    margin: 0 5vw 0 5px;
    @media screen and (max-width: $break-small) {
      margin: 0 5vw 0 1vw;
    }

    
}

.options {
  width: 90%;
  height: auto;
  padding: 2vw 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;

  &__action {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 20px;
    right: 5%;

    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;

    display: flex;
    align-items: center;
    justify-content: center;

    img { 
      width: 75%;
    }
  }
  span {
    color: #3EC196;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  }
  &__icon {
    width: 15px;
    height: 15px;
    position: relative;

    border-radius: 50%;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5vw 0 1vw;

    img { 
      width: 75%;
    }
  }
  @media screen and (max-width: $break-small) {
    width: 90%;
    height: auto;
    padding: 6vw 0;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;

    &__action {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 20px;
      right: 5%;

      border-radius: 50%;
      position: absolute;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;

      display: flex;
      align-items: center;
      justify-content: center;

      img { 
        width: 75%;
      }
    }
    span {
      color: #3EC196;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    }
    &__icon {
      width: 25px;
      height: 25px;
      position: relative;

      border-radius: 50%;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5vw 0 2vw;

      img { 
        width: 75%;
      }
    }

  }

}

.controls {
  width: 90%;
  display: flex;
  margin-bottom: 15px;
  position: relative;

  &__date {
    font-size: 10px;
    font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;
  }

  &__title {
    width: 90%;
    font-weight: bolder;
    font-size: 20px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    color: $darkColor;
    text-align: left;
    &::placeholder {
      color: $darkColor;
    }
  }
  &__sub-title {
    width: 90%;
    font-weight: bolder;
    font-size: 16px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    color: $darkColor;
    &::placeholder {
      color: $darkColor;
    }
  }
  &__action {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content:baseline;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  &__tasks {
    width: 100%;
  }
  &__task-list {
    width: 100%;
    padding: 0 1vw 6vw 25px;
    position: relative;
    margin-left: 15px;
  }
  &__task-border {
    position: absolute;
    top: 0;
    height: 100%;
    left: -1px;
    background-color: #fff;
    border-left: .09rem solid #b5e3c8;
  } 
  &__task-checked {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -14px;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      height: 50%;
    }
  }
  &__task-checked-active {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -14px;
    background-color: #fff;
    background-color: #b5e3c8;
    border: .09rem solid #b5e3c8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80%;
      height: 80%;
    }
  } 
  .completed {
    color: #3ec196;
    font-style: italic;
  }
  &__more {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
  } 

  textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    padding-left: 0;
    font-size: 14px;
    background-color: transparent;
    font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

  }

  @media screen and (max-width: $break-small) {

    width: 90%;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    
    &__title {
      width: 90%;
      font-weight: bolder;
      font-size: 6vw;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
      color: $darkColor;
      text-align: left;
      &::placeholder {
        color: $darkColor;
      }
    }
    &__sub-title {
      width: 90%;
      font-weight: bolder;
      font-size: 4.5vw;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
      color: $darkColor;
      &::placeholder {
        color: $darkColor;
      }
    }
    &__action {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content:baseline;
      position: relative;
      img {
        width: 100%;
      }
    }

    &__tasks {
      width: 100%;
    }
    &__task-list {
      width: calc(69vw - 70px);
      padding: 0 1vw 6vw 25px;
      // background-color: red;
      position: relative;
      // border-left: 1px solid #b5e3c8;
      margin-left: 15px;
    }
    &__task-border {
      position: absolute;
      top: 0;
      height: 100%;
      left: -1px;
      background-color: #fff;
      border-left: .09rem solid #b5e3c8;
    } 
    &__task-checked {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -14px;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
        height: 50%;
      }
    }
    &__task-checked-active {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -14px;
      background-color: #fff;
      background-color: #b5e3c8;
      border: .09rem solid #b5e3c8;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        height: 80%;
      }
    } 
    .completed {
      color: #3ec196;
      font-style: italic;
    }
    &__more{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;

    } 

    textarea {
      width: 100%;
      border: none;
      outline: none;
      padding: 0;
      padding-left: 0;
      font-size: 4.5vw;
      background-color: transparent;
      font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

    }
  }
}
.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
  
  // padding: 4vw 2vw;
}
.task-input {
  font-size: 18px;
  font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

}
.home-input {
  width: 300px;
  background-color: #e6ffee;
  border-radius: $borderRadius;
  border-top-left-radius: 0;
  text-align: center;
  font-size: 15px;
  margin: 15px auto;
  padding: 1vw 2vw; 

  @media screen and (max-width: $break-small) {
    font-size: 5vw;
    margin: 30px auto;
    padding: 4vw 2vw; 
  }
  
}

</style>
