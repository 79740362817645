import firebase from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import Vue from "vue";
import { deployment } from "@/deployment.js";
const axios = require("axios").default;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsgXgK3LRke5oLCMqIFM_HVuWHBxbrnTs",
  authDomain: "roxyart-io.firebaseapp.com",
  databaseURL: "https://roxyart-io.firebaseio.com",
  projectId: "roxyart-io",
  storageBucket: "roxyart-io.appspot.com",
  messagingSenderId: "903224539936",
  appId: "1:903224539936:web:6bde71c39c276cd768f545",
  measurementId: "${config.measurementId}",
};

// Initialize Firebase
//Vue.use(firebase);
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const authorize = getAuth();

const state = {
  user: [],
};
const getters = {
  user: (state) => state.user,
};
const actions = {
  LOGIN(context, payload) {
    console.log(payload);
    signInWithEmailAndPassword(authorize,payload.email, payload.password)
      .catch((error) => {
        window.console.log(error);
      })
      .then((user) => {
        console.log(user.user.uid);
        context.dispatch("GET_TOKEN", user.user.uid);
      });
  },
  async LINK_PASSWORD(context, payload) {
    var user = firebase.auth().currentUser;
    var newPassword = payload.localUser.password;
    const timestamp = new Date();

    const userRef = db
      .collection("users")
      .doc("user")
      .collection("data")
      .doc(payload.beforeId);

    try {
      const response = await db.runTransaction(async (t) => {
        const userDoc = await t.get(userRef);
        await user.updatePassword(newPassword);
        const newDoc = userDoc.data();
        newDoc.id = user.uid;
        newDoc.roles[0].status = "ACTIVE";
        newDoc.updatedAt = timestamp;
      
        db.collection("users")
          .doc("user")
          .collection("data")
          .doc(user.uid)
          .set(newDoc);

        t.delete(userRef);

        return userDoc.data();
      });

      if (response) {
        console.log(response);
        context.dispatch("GET_TOKEN", user.uid);
      }
    } catch (e) {
      console.log("Transaction failure:", e);
    }
  },
  async GET_TOKEN(context, uid) {
    console.log("new link");
    console.log("this");
    axios
      .get(
        `https://us-central1-roxyart-io.cloudfunctions.net/auth-api/authentication/${uid}`
      )
      .then((response) => {
        location.replace(
          `${deployment.alcoveUrl}/?accessToken=${response.data}`
        );
      });
  },
};

export const auth = {
  state,
  actions,
  getters,
};
