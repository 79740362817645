const env = {
    LOCAL: {
        alcoveUrl: 'http://localhost:8100'
    },
    DEV: {
        alcoveUrl:  'https://alcove.roxyart.io'
    },
    TEST: {
        alcoveUrl:  'https://alcove.roxyart.io'
    },
    PROD: {
        alcoveUrl:  'https://alcove.roxyart.io'

    }
};

// change for production
//export const deployment = env.LOCAL;
//export const deployment = env[process.env.VUE_APP_DEPLOYMENT];
export const deployment = env.PROD;