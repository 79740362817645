//import * as firebase from "firebase/app";
import "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";
import Vue from 'vue';
import router from '../../router'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsgXgK3LRke5oLCMqIFM_HVuWHBxbrnTs",
    authDomain: "roxyart-io.firebaseapp.com",
    databaseURL: "https://roxyart-io.firebaseio.com",
    projectId: "roxyart-io",
    storageBucket: "roxyart-io.appspot.com",
    messagingSenderId: "903224539936",
    appId: "1:903224539936:web:6bde71c39c276cd768f545",
    measurementId: "${config.measurementId}"
  };

// Initialize Firebase
//Vue.use(firebase);
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);
const storage = getStorage(app)

const state = {
    collections: [],
    collection: {},
    isLoading: false
}
const getters = {
    collections: state => state.collections,
    collection: state => state.collection,
    isLoading: state => state.isLoading,
}
const mutations = {
    loadCollections(state, payload) {
    state.collections = payload;
    },
    loadCollection(state, payload) {
        state.collection = payload;
    },
    loading(state){
    state.isLoading = true;
    }
  }
const actions = {
    LOAD_COLLECTIONS(context) {
        context.commit('loading') 
        db.collection("alcove").doc('database').collection('collections')
        .onSnapshot(function(querySnapshot) {
            const payload = []
            querySnapshot.forEach(function(doc) {
                payload.push(doc.data())
            });
            context.commit('loadCollections', payload)
        });
    },
    CREATE_COLLECTION(context, payload) {
        context;
        const createdAt = new Date();
        
        db.collection("alcove").doc('database').collection('collections').add(payload.collection)
        .then((docRef) => {
          return {
            updateRecord: db.collection("alcove").doc('database').collection('collections').doc(docRef.id).
            update({
                id: docRef.id,
                createdAt: createdAt
            }),
            changeRoute: router.push('/collections')
          }
        })
    },
    LOAD_COLLECTION(context, payload) {
        context.commit('loading') 
        db.collection("collections").doc(payload)
        .onSnapshot(function(doc){
            window.console.log("Current data: ", doc.data());
            context.commit('loadClaimProfile', doc.data());
        });
    },
}

export const collections = {
    state,
    mutations,
    actions,
    getters
}

