import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import AccountLink from '@/views/AccountLink'

import { routes } from '@/utils/constants'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: routes.Dashboard,
            component: Login
        },
        {
            path: '/register',
            name: routes.register,
            component: AccountLink
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
