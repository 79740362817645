import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
      },
    theme: {
        themes: {
          light: {
            primary: '#171B3A',
            secondary: '#C6D2FF',
            accent: '#C6D2FF',
            error: '#b71c1c',
          },
        },
      },
});
