import Vue from 'vue';
import Vuex from 'vuex';
import { toolbarActions } from './modules/toolbarActions';
import { catalog } from './modules/catalog';
import { collections } from './modules/collections';
import { auth } from './modules/auth';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        toolbarActions,
        auth,
        catalog,
        collections
    }
});
