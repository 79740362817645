<template>
    <div class="login">
        <v-card class="login__card">
            <v-img width="200px" class="mb-8" src="../../static/logo.png"></v-img>
            <v-text-field
                v-if="!addPassword"
                v-model="user.email"
                label="Confirm Email"
                :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
                v-if="addPassword"
                v-model="user.password"
                label="Password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
            ></v-text-field>

            <v-btn v-if="!addPassword" width="100%" height="45px" class="mt-8" depressed color="#333" dark @click="login">
                Confirm
            </v-btn>
            <v-btn v-if="addPassword" width="100%" height="45px" class="mt-8" depressed color="#333" dark @click="linkPassword">
                Submit
            </v-btn>
        </v-card>
    </div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export default {
    data () {
        return {
            user: {
                email: '',
                password: '',
                verifyPassword: '',
                uid: '',
                roles: [],
                tenantId: ''
            },
            requestedUser: {},
            addPassword: false,
            show: false,
            password: 'Password',
            rules: {
                required: value => !!value || 'Required.'
            }
        };
    },
    created () {
        if (this.$route.query.onboard) {
            console.log(this.$route.query.onboard);
        }
    },
    methods: {
        login () {
            if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
                var email = this.user.email;
                firebase.auth().signInWithEmailLink(email, window.location.href)
                    .then((result) => {
                        console.log(result);
                        if (result.additionalUserInfo.isNewUser) {
                            this.addPassword = true;
                        } else {
                            console.log(result);
                            this.$store.dispatch('GET_TOKEN', result.user.uid);
                        }
                        this.user.uid = result.user.uid;
                        this.user.tenantId = this.$route.query.onboard;
                    })
                    .catch((error) => {
                        if (error.code === 'auth/invalid-action-code') {
                            console.log(error);
                        }
                    });
            }
        },
        async linkPassword () {
            const payload = {
                localUser: this.user,
                userData: this.requestedUser,
                beforeId: this.$route.query.userId
            }
            this.$store.dispatch('LINK_PASSWORD', payload);
        }
    }
};
</script>

<style lang="scss" scoped>
$break-small: 900px;
.login {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        width: 500px;
        padding: 4em 2em;
        position: relative;
        box-shadow: 0 0 15px #ddd;
        margin-top: -100px;
    }

    @media screen and (max-width: $break-small) {
      position: fixed;
      &__card {
        height: 100%;
        margin-top: unset;
      }
    }
}
</style>
