<template>
    <div class="login">
        <v-card class="login__card">
            <v-img width="200px" class="mb-8" src="../../static/logo.png"></v-img>
          <v-text-field
            v-model="user.email"
            label="Email"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            label="Password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></v-text-field>
          <v-btn width="100%" height="45px" class="mt-8" depressed color="#333" dark @click="login">
            Login
          </v-btn>
        </v-card>
    </div>
</template>

<script>
    export default {
        data() {
          return {
            user: {
              email: '',
              password: ''
            },
            show: false,
            password: 'Password',
            rules: {
              required: value => !!value || 'Required.'
            }
          }
        },
        methods: {
          login(){
            this.$store.dispatch('LOGIN', this.user);
          }
        }
    }
</script>

<style lang="scss" scoped>
$break-small: 900px;
.login {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        width: 500px;
        padding: 4em 2em;
        position: relative;
        box-shadow: 0 0 15px #ddd;
        margin-top: -100px;
    }

    @media screen and (max-width: $break-small) {
      position: fixed;
      &__card {
        height: 100%;
        margin-top: unset;
      }
    }
}
</style>