//import * as firebase from "firebase/app";
import "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";
import Vue from 'vue';
import router from '../../router'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsgXgK3LRke5oLCMqIFM_HVuWHBxbrnTs",
    authDomain: "roxyart-io.firebaseapp.com",
    databaseURL: "https://roxyart-io.firebaseio.com",
    projectId: "roxyart-io",
    storageBucket: "roxyart-io.appspot.com",
    messagingSenderId: "903224539936",
    appId: "1:903224539936:web:6bde71c39c276cd768f545",
    measurementId: "${config.measurementId}"
  };

// Initialize Firebase
//Vue.use(firebase);
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const state = {
    catalog: [],
    artwork: {
        creator: '',
        title: '',
        year: '',
        medium: '',
        location: '',
        status: '',
        images: {},
        usdz: null,
        dimensions: {
            width: '',
            height: '',
            depth: ''
        },
        price: '',
        weight: '',
        forSale: '',
        checkUSDZ: true
    }
}
const getters = {
    catalog: state => state.catalog,
    artwork: state => state.artwork,
}
const mutations = {
    loadCatalog(state, payload) {
    state.catalog = payload;
    },
    loadArtwork(state, payload) {
        state.artwork = payload;
    }
  }
const actions = {
    LOAD_CATALOG(context) {
        context.commit('loading') 
        db.collection("alcove").doc('database').collection('catalog')
        .onSnapshot(function(querySnapshot) {
            const payload = []
            querySnapshot.forEach(function(doc) {
                payload.push(doc.data())
            });
            context.commit('loadCatalog', payload)
        });
    },
    CREATE_ARTWORK(context, payload) {
        context;
        const createdAt = new Date();
        let namePrefix = 'artwork'
        
        db.collection("alcove").doc('database').collection('catalog').add(payload.artwork)
        .then((docRef) => {
          return {
            updateRecord:
            storage.ref("catalog/" + docRef.id + "/" + namePrefix + '-' + docRef.id + '.' + payload.file.fileExt)
            .putString(payload.file.newUrl, 'data_url')
            .then(data => {
            data;
            storage.ref("catalog/" + docRef.id + "/" + namePrefix + '-' + docRef.id + '.' + payload.file.fileExt)
                .getDownloadURL()
                .then(url => {
                return db.collection("alcove").doc('database').collection('catalog').doc(docRef.id)
                    .update({
                    photoURL: url,
                    id: docRef.id,
                    filename: namePrefix + '-' + docRef.id + '.' + payload.file.fileExt,
                    createdAt: createdAt,
                    checkUSDZ: true
                    })
                })
                .catch(error => {
                    window.console.error(error)
                })
            }),
            changeRoute: router.push('/catalog')
          }
        })
    },
    LOAD_ARTWORK(context, payload) {
        context.commit('loading') 
        db.collection("alcove").doc('database').collection('catalog').doc(payload)
        .onSnapshot(function(doc){
            window.console.log("Current data: ", doc.data());
            context.commit('loadArtwork', doc.data());
        });
    },
}

export const catalog = {
    state,
    mutations,
    actions,
    getters
}
